import { VisibilityOff } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { NextRouter, useRouter } from "next/router";
import { ReactNode } from "react";
import { useIsSmallScreen } from "shared/hooks/use-is-small-screen";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DashboardSidebarLogOutButton from "./dashboard/dashboard-sidebar-log-out-button";

const SMALL_SCREEN_ENABLED_ROUTES = new Set([
  "/dashboard",
  "/dashboard/account",
  "/portfolio",
  "/portfolio/accounts/[managedAccountId]",
  "/portfolio/external-accounts/[heldawayAccountId]",
  "/savvy-portfolio/[portfolioId]",
  "/onboarding",
  "/login",
  "/reset-password",
  "/create-password",
  "/invalid-invite-token-dialog",
  "/refresh-invite-token-dialog",
  "/password-created-success-small-screen",
  "/redirect-to-mobile-advisor-app/reset-password",
  "/redirect-to-mobile-client-app/reset-password",
]);

export default function SavvySmallScreenWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const router = useRouter();
  const isSmallScreen = useIsSmallScreen();

  if (isSmallScreen && !urlIsAllowedForSmallScreen(router)) {
    return (
      <SavvySmallScreenView
        onClickBackArrow={() => {
          router.back();
        }}
      />
    );
  }
  return <>{children}</>;
}

export type SavvySmallScreenViewProps = {
  onClickBackArrow: () => void;
};
export function SavvySmallScreenView({
  onClickBackArrow,
}: SavvySmallScreenViewProps) {
  return (
    <Stack
      alignItems="flex-start"
      direction="column"
      height="90vh"
      sx={{ p: 2 }}
    >
      <IconButton onClick={onClickBackArrow}>
        <ArrowBackIcon />
      </IconButton>
      <Stack
        display="flex"
        flexGrow={1}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <VisibilityOff fontSize="large" />
        <Box sx={{ my: 1, px: 3, textAlign: "center" }}>
          <Typography variant="subtitle1" color="textSecondary">
            This page on smaller screens is coming soon!
          </Typography>
        </Box>
        <Box sx={{ my: 1, px: 3, textAlign: "center" }}>
          <Typography variant="body2" color="textSecondary">
            For now, please open this page on your computer or enlarge your
            screen.
          </Typography>
        </Box>
      </Stack>
      <DashboardSidebarLogOutButton fullWidth={false} />
    </Stack>
  );
}

function urlIsAllowedForSmallScreen({ route }: NextRouter): boolean {
  return (
    // process.env.NODE_ENV === "development" ||
    SMALL_SCREEN_ENABLED_ROUTES.has(route)
  );
}
