/* eslint-disable no-restricted-imports */
import type {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
} from "@apollo/client";
import { useQuery } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum"; // TODO: SAV-3878 build react-native support
import { useEffect } from "react";
import { useAuth } from "shared/hooks/use-auth";
import { loggedInAsAdvisorID } from "shared/utils/savvy-auth";

const isAuthorizationError = (errorMessage: string) => {
  return errorMessage.includes("QueryType's authorization check");
};

export function useQueryWithErrorLogging<
  TVariables extends OperationVariables,
  TData
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const resp = useQuery(query, options);
  const advisorId = loggedInAsAdvisorID();
  const { user: authUser, reauth } = useAuth();

  useEffect(() => {
    if (resp.error) {
      try {
        throw new Error(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error not sure how to fix
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          `${query.definitions[0]?.name?.value}
          error_name=${resp.error.name} 
          error_message=${resp.error.message}`
        );
      } catch (error) {
        console.error(error);
        datadogRum.addError(error, {
          user_id: authUser?.id,
          logged_in_as_user_id: advisorId,
        });
        if (isAuthorizationError(resp.error.message)) {
          reauth();
        }
      }
    }
  }, [resp.error]);

  return resp;
}
