import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { createAuthApi } from "shared/api/auth-api";
import { ApolloClient, useApolloClient } from "@apollo/client";
import {
  clearUserInfo,
  isExpiredJWT,
  LoginData,
} from "shared/utils/savvy-auth";
import { removeSalesforceAccessToken } from "shared/utils/salesforce";
import {
  CurrentUserQuery,
  JWTContextUser,
  fetchCurrentUser,
} from "shared/api/user-api";
import { ResultOf } from "shared/gql";
import { useRouter } from "shared/hooks/use-router";
import { JWT_STORAGE_KEY, LocalStorage } from "shared/utils/local-storage";
import { isSharedEmployeeAccount } from "shared/utils/savvy-auth";

export type JWTContextState = {
  err?: string;
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: JWTContextUser | null;
};

type NewCurrentUserAndJWTContextUser = JWTContextUser & {
  newCurrentUserData: ResultOf<typeof CurrentUserQuery> | undefined;
};
export type LoginReturn = {
  loginErr?: string;
};
export type AuthContextValue = {
  platform: "JWT";
  login: (data: LoginData) => Promise<LoginReturn>;
  logout: () => Promise<boolean>;
  update: (
    user: JWTContextUser | NewCurrentUserAndJWTContextUser
  ) => Promise<void>;
  reauth: () => Promise<void>;
} & JWTContextState;

export type AuthProviderProps = {
  children: ReactNode;
};

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
    user: JWTContextUser | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: JWTContextUser;
  };
};

type UpdateAction = {
  type: "UPDATE";
  payload: {
    user: JWTContextUser;
  };
};

type ErrorEncounteredAction = {
  type: "ERROR";
  payload: {
    err?: string;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | UpdateAction
  | ErrorEncounteredAction;

const initialState: JWTContextState = {
  err: undefined,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

function reducer(state: JWTContextState, action: Action): JWTContextState {
  switch (action.type) {
    case "INITIALIZE":
      return {
        ...state,
        ...action.payload,
        isInitialized: true,
      };
    case "LOGIN":
    case "UPDATE":
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case "ERROR":
      return {
        ...state,
        ...action.payload,
        isAuthenticated: false,
        user: null,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
}

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve({}),
  update: () => Promise.resolve(),
  logout: () => Promise.resolve(false),
  reauth: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const [state, dispatch] = useReducer(reducer, initialState);
  const apolloClient: ApolloClient<object> = useApolloClient();
  const authApi = createAuthApi(apolloClient);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = await LocalStorage.getItem(JWT_STORAGE_KEY);

        if (accessToken && !isExpiredJWT(accessToken)) {
          const user = await authApi.me(accessToken);

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          if (isExpiredJWT(accessToken)) {
            clearUserInfo(apolloClient);
          }
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error("JWT / Auth Error", err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    void initialize();
  }, []);

  const login = async (data: LoginData): Promise<LoginReturn> => {
    const { err, jwt } = await authApi.login(data);
    if (err != null) {
      dispatch({
        type: "ERROR",
        payload: {
          err,
        },
      });
    } else {
      await LocalStorage.setItem(JWT_STORAGE_KEY, jwt);
      const user = await authApi.me(jwt);

      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    }
    return { loginErr: err };
  };
  const update = (user: JWTContextUser) => {
    dispatch({
      type: "UPDATE",
      payload: {
        user,
      },
    });
    return Promise.resolve();
  };

  const logout = async () => {
    const currentUserData = await apolloClient.query({
      query: CurrentUserQuery,
    });
    if (!isSharedEmployeeAccount(currentUserData.data.currentUser.user)) {
      // We don't want to invalidate the jwt for shared users as it would log out
      // everyone else logged into the same account.
      await authApi.logout();
    }
    clearUserInfo(apolloClient);
    removeSalesforceAccessToken();
    authApi.reset();
    dispatch({
      type: "LOGOUT",
    });

    return true;
  };

  const reauth = async (): Promise<void> => {
    try {
      await fetchCurrentUser(apolloClient, true);
    } catch (error) {
      logout();
      await router.push("/login");
    }
  };

  return (
    <AuthContext.Provider
      // TODO: Every re-render of one consumer will cause a re-render of all consumers.
      //       We should memoize the value prop and it's dependencies to prevent this.
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...state,
        platform: "JWT",
        login,
        update,
        logout,
        reauth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
