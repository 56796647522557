import { Button } from "@mui/material";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useTheme } from "shared/hooks/use-theme";
import {
  loggedInAsAdvisorID,
  removeLoggedInAsAdvisorID,
} from "shared/utils/savvy-auth";
import { useMutationWithErrorLogging } from "shared/hooks/useMutationWithErrorLogging";
import { STOP_IMPERSONATING_USER } from "../logout-as-button";
import { useAuth } from "shared/hooks/use-auth";
import { useQueryWithErrorLogging } from "shared/hooks/useQueryWithErrorLogging";
import { CurrentUserQuery } from "shared/api/user-api";
import toast from "react-hot-toast";

type DashboardSidebarLogOutButtonProps = {
  onLogOut?: () => void;
  fullWidth?: boolean;
};

const DashboardSidebarLogOutButton = ({
  onLogOut,
  fullWidth,
}: DashboardSidebarLogOutButtonProps) => {
  const theme = useTheme();
  const auth = useAuth();
  const { logout, user, update } = auth;

  const { data: newCurrentUserData, refetch } =
    useQueryWithErrorLogging(CurrentUserQuery);

  const [stopImpersonatingUser] = useMutationWithErrorLogging(
    STOP_IMPERSONATING_USER
  );

  const isAdvisorLoggedInAsClient = !!loggedInAsAdvisorID();

  const handleLogout = async (): Promise<void> => {
    if (isAdvisorLoggedInAsClient) {
      await stopImpersonatingUser({
        variables: {
          input: {},
        },
      });

      await refetch();

      update({ ...user, newCurrentUserData });
      removeLoggedInAsAdvisorID();
    }
    try {
      if (onLogOut) onLogOut();
      await logout();
      window.location.href = "/login";
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  };

  return (
    <Button
      startIcon={<LogoutRoundedIcon />}
      aria-label="log out"
      size="small"
      color="secondary"
      fullWidth={fullWidth}
      sx={{
        color: theme.palette.neutral[600],
        borderRadius: 1,
        height: "34px",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        justifyContent: "flex-start",
      }}
      onClick={() => {
        handleLogout();
      }}
    >
      Log out
    </Button>
  );
};

export default DashboardSidebarLogOutButton;
