import { ApolloClient, useApolloClient } from "@apollo/client";
import { Button } from "@mui/material";
import { useAuth } from "shared/hooks/use-auth";
import { textColors } from "shared/theme";
import { removeLoggedInAsAdvisorID } from "shared/utils/savvy-auth";
import { useMutationWithErrorLogging } from "shared/hooks/useMutationWithErrorLogging";
import { graphql } from "shared/gql";

export const STOP_IMPERSONATING_USER = graphql(/* GraphQL */ `
  mutation StopImpersonatingUser($input: StopImpersonatingUserInput!) {
    stopImpersonatingUser(input: $input) {
      trueUser {
        id
      }
      currentUser {
        id
      }
    }
  }
`);

export const LogoutAsButton = ({
  useGenericCopy = false,
  destinationUrl,
}: {
  useGenericCopy?: boolean;
  destinationUrl?: string;
}) => {
  const { user: authUser } = useAuth();
  const [stopImpersonatingUser] = useMutationWithErrorLogging(
    STOP_IMPERSONATING_USER
  );
  const apolloClient: ApolloClient<object> = useApolloClient();

  const handleLogoutFromClient = async () => {
    await stopImpersonatingUser({
      variables: {
        input: {},
      },
    });

    removeLoggedInAsAdvisorID();
    apolloClient.clearStore();
    window.location.href = destinationUrl || "/";
  };
  return (
    <Button
      size="small"
      variant="outlined"
      sx={{
        mr: 4,
        borderColor: textColors.disabled,
        color: textColors.charcoal,
        "&:hover": {
          borderColor: textColors.disabled,
        },
        alignSelf: "center",
      }}
      onClick={handleLogoutFromClient}
    >
      {useGenericCopy
        ? "Log out of client account"
        : `Log out of ${authUser?.firstName} ${authUser?.lastName}`}
    </Button>
  );
};
